
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import AddSchool from "@/components/1crm/sekolah/add-sekolah.vue";

export default defineComponent({
  name: "Tambah Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    AddSchool,
    // GoogleMap
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      setCurrentPageBreadcrumbs("Tambah Sekolah", ["Data"]);
    });

    return {
      submitButton,
    };
  },
});
