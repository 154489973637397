
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { AnyObject } from "yup/lib/types";
import { AnyFunction } from "element-plus/lib/utils/types";
import { ArrayLocale } from "yup/lib/locale";
// import GoogleMap from '@/components/GoogleMap.vue'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Multiselect from "@vueform/multiselect";

interface tambahSekolah {
  name: string;
  phone: string;
  email: any;
  initial: string;
  picName: string;
  picPhone: string;
  fundingName: string;
  picEmail: any;
  schoolType: string;
  salesArea: any;
  status: string;
  targetLive: any;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
  bankData: any;
  bankAreaId: any;
  bankBranchId: any;
  gps: {
    longitude: number;
    latitude: number;
  };
  npwp: {
    number: string;
    imageUrl: string;
  };
  referenceFrom: string;
  customApp: string;
  note: string;
}

interface tambahNpwp {
  idSekolah: string;
  file: string;
}

interface items {
  listBank: any;
  listArea: any;
  listBranch: any;
  listFunding: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
  user_sales_area: any;
  prov: string;
  kab: string;
  disableButton: boolean;


  errorschoolName: string;
  errorprovince: string;
  errorcity: string;
  errorregency: string;
  errordetail: string;
  erroremail: string;
}

export default defineComponent({
  name: "Tambah Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
    // GoogleMap
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const store = useStore();

    // loading
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    // end loading

    let items = reactive<items>({
      listBank: [],
      listArea: [],
      listBranch: [],
      listFunding: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      user_sales_area: [],
      prov: "",
      kab: "",
      disableButton: false,

      errorschoolName: "",
      errorprovince: "",
      errorcity: "",
      errorregency: "",
      errordetail: "",
      erroremail: "",
    });

    const tambahSekolahValidator = Yup.object().shape({
      name: Yup.string().required("Nama Sekolah Wajib Diisi"),
      // phone: Yup.string().required('Nomor Telepon Wajib Diisi'),
      email: Yup.string().email('Email Harus Valid'),
      // initial: Yup.string().required('Initial Wajib Diisi'),
      // picPhone: Yup.string().required('Nomor Telepon Sales Wajib Diisi'),
      // picName: Yup.string().required('Nama PIC Sekolah Wajib Diisi'),
      picEmail: Yup.string().email('Email Harus Valid'),
      // salesArea: Yup.string().required('Nama Sales Wajib Diisi'),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
      detail: Yup.string().required("Alamat Wajib Diisi"),
    });

    const tambahNpwp = reactive<tambahNpwp>({
      idSekolah: "",
      file: "",
    });

    const tambahSekolah = reactive<tambahSekolah>({
      name: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
      gps: {
        longitude: 0,
        latitude: 0,
      },
      npwp: {
        number: "",
        imageUrl: "",
      },
      phone: "",
      email: "",
      schoolType: "School",
      salesArea: null,
      initial: "",
      picName: "",
      bankAreaId: "",
      bankBranchId: "",
      picPhone: "",
      picEmail: "",
      referenceFrom: "",
      bankData: [],
      fundingName: "",
      status: "Leads",
      targetLive: null,
      customApp: "false",
      note: "",
    });

    const saveChanges1 = () => {
      if (
        tambahSekolah.name === "" ||
        tambahSekolah.address.province === "" ||
        tambahSekolah.address.city === "" ||
        tambahSekolah.address.regency === "" ||
        tambahSekolah.address.detail === ""
      ) {
        items.errorschoolName = "Nama Sekolah Wajib Diisi";
        items.errorprovince = "Provinsi Wajib Diisi";
        items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        items.errorregency = "Kecamatan Wajib Diisi";
        items.errordetail = "Alamat Wajib Diisi";
      } else {

        var cekvalidall = true;
        if (tambahSekolah.email) {
          var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
          var text = tambahSekolah.email;

          if (text.match(pattern)) {
            items.erroremail = "";
            cekvalidall = true;
          }
          else {
            items.erroremail = "Email Harus Valid";
            cekvalidall = false;
          }

        }

        if (cekvalidall == true) {
          if (tambahSekolah.bankAreaId == "") {
            tambahSekolah.bankAreaId = null
          }
          if (tambahSekolah.bankBranchId == "") {
            tambahSekolah.bankBranchId = null
          }

          isLoading.value = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.postWithData(
            "crmv2/main_school/school/school",
            tambahSekolah
          )
            .then((res) => {
              tambahNpwp.idSekolah = res.data._id;
              if (tambahNpwp.file) {
                saveNpwp(); //function save npwp setelah save sekolah
                submitButton.value?.removeAttribute("data-kt-indicator");
              } else {
                toast.success("Berhasil Tambah Sekolah");
                router.push({ path: "/superadmin/sekolah" });

                isLoading.value = false;
              }

            })
            .catch((e) => {
              if (e.response.status == 401) {
                toast.error(e.response.data.detail);
                router.push('/sign-in');
                isLoading.value = false;
              } else {
                toast.error(e.response.data.detail);
                isLoading.value = false;
              }
            });
          submitButton.value?.removeAttribute("data-kt-indicator");

        }


      }

    };

    // untuk save npwp
    const saveNpwp = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      let formData = new FormData();
      formData.append('npwpFile', tambahNpwp.file);

      ApiService.postWithData(
        "/crmv2/main_school/school/school/upload_npwp?schoolId=" + tambahNpwp.idSekolah,
        formData
      )
        .then((res) => {
          toast.success("Berhasil Tambah Sekolah");
          router.push({ path: "/superadmin/sekolah" });

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          }
        });
    };

    const getListBank = () => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          items.listBank = response.data;

        }
      );
    };
    const changeBank = (e) => {
      console.log(e)
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          items.listBank = response.data;
          if (tambahSekolah.bankData.length < 1) {

            tambahSekolah.bankAreaId = ""
            tambahSekolah.bankBranchId = ""
          }
        }
      ).catch(e => {

      });
    }

    const changeListArea = (bankId) => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/combo_areas").then(
        (response) => {
          items.listArea = response.data;
          items.listBranch = []
          if (tambahSekolah.bankData.length < 1) {

tambahSekolah.bankAreaId = ""
tambahSekolah.bankBranchId = ""
}
          // tambahSekolah.bankBranchId = ""

        }
      ).catch(e => {
        items.listArea = []
        tambahSekolah.bankAreaId = ""
      });
    }
    const getListArea = (bankId) => {
      console.log(bankId)
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/combo_areas").then(
        (response) => {
          items.listArea = response.data;

        }
      ).catch(e => {
        items.listArea = []
        tambahSekolah.bankAreaId = ""
      });
    };
    const changeListBranch = (bankId, areaId) => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/" + areaId + "/combo_branchs").then(
        (response) => {
          console.log(response.data, "cdf")
          tambahSekolah.bankBranchId = ""
          items.listBranch = response.data;
        }
      ).catch(e => {
        tambahSekolah.bankBranchId = ""
        items.listBranch = []
      });
    }
    const getListBranch = (bankId, areaId) => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/" + areaId + "/combo_branchs").then(
        (response) => {
          console.log(response.data, "cdf")
          items.listBranch = response.data;
        }
      ).catch(e => {
        tambahSekolah.bankBranchId = ""
        items.listBranch = []
      });
    };
    const onChangeEmail = (event) => {
      if (event.target.value) {
        var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
        var text = event.target.value;
        if (text.match(pattern)) {
          items.erroremail = "";
        }
        else {
          items.erroremail = "Email Harus Valid";
        }
      }

    };

    const pilihProv = (event) => {
      if (event) {
        tambahSekolah.address.province = event;
        getKabupaten();
      } else {
        tambahSekolah.address.province = "";
        tambahSekolah.address.city = "";
        tambahSekolah.address.regency = "";
        items.errorprovince = "Provinsi Wajib Diisi";
        items.prov = '0';
        getKabupaten();
      }
    };

    const pilihKab = (event) => {
      if (event) {
        tambahSekolah.address.city = event;
        getKecamatan();
      } else {
        tambahSekolah.address.city = "";
        tambahSekolah.address.regency = "";
        items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        items.kab = '0';
        getKecamatan();
      }
    };

    const pilihKec = (event) => {
      if (event) {
        tambahSekolah.address.regency = event;
        getKecamatan();
      } else {
        tambahSekolah.address.regency = "";
        items.errorregency = "Kecamatan Wajib Diisi";
        items.kab = '0';
        getKecamatan()
      }
    }

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === tambahSekolah.address.province
      );

      if (indexProv != null) {


        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.prov = '1';
          items.kabupatenOptions = response.data;

          getComboUser();

        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === tambahSekolah.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kab = '1';
          items.kecamatanOptions = response.data;
        });
      }
    };

    const combo_funding = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_school/combo/school/get_combo_bank_funding"
      ).then((response) => {
        items.listFunding = response.data;
      });
    };

    const getComboUser = () => {

      if (tambahSekolah.address.province) {

        isLoading.value = true;

        ApiService.getWithoutSlug(
          "crmv2/main_user/sales/team/get_combo_user?area=" + tambahSekolah.address.province
        ).then((response) => {
          items.user_sales_area = response.data;

          isLoading.value = false;
        })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push('/sign-in');
            } else {
              toast.error(e.response.data.detail);
            }

            isLoading.value = false;
          })

      } else {

        isLoading.value = true;

        ApiService.getWithoutSlug(
          "crmv2/main_user/sales/team/get_combo_user"
        ).then((response) => {
          items.user_sales_area = response.data;

          isLoading.value = false;
        })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push('/sign-in');
            } else {
              toast.error(e.response.data.detail);
            }

            isLoading.value = false;
          })
      }

    };

    const combo_user_sales_area = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_AREA"
      ).then((response) => {
        items.user_sales_area = response.data;
      })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
          } else {
            toast.error(e.response.data.detail);
          }
        });
    };

    function handleFileUpload(event) {
      tambahNpwp.file = event.target.files[0];
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);

      getProvinsi();
      getListBank();
      combo_funding();
      getComboUser();
    });

    return {
      submitButton,
      saveChanges1,
      tambahSekolah,
      tambahSekolahValidator,
      items,
      getKabupaten,
      getKecamatan,
      getListBank,
      getListArea,
      getListBranch,
      changeListArea,
      changeListBranch,
      changeBank,
      combo_funding,
      combo_user_sales_area,
      handleFileUpload,
      tambahNpwp,
      saveNpwp,
      getComboUser,
      pilihProv,
      pilihKab,
      pilihKec,

      onChangeEmail,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
